define("discourse/plugins/discourse-multilingual/discourse/routes/admin-multilingual", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    beforeModel(transition) {
      if (transition.intent.url === "/admin/multilingual" || transition.intent.name === "adminMultilingual") {
        this.transitionTo("adminMultilingualLanguages");
      }
    },
    model() {
      return (0, _ajax.ajax)("/admin/multilingual");
    },
    setupController(controller, model) {
      controller.setProperties({
        tagGroupId: model.content_language_tag_group_id,
        documentationUrl: "https://thepavilion.io/c/knowledge/discourse/multilingual"
      });
    },
    actions: {
      showSettings() {
        const controller = this.controllerFor("adminSiteSettings");
        this.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
          controller.set("filter", "multilingual");
          controller.set("_skipBounce", true);
          controller.filterContentNow("plugins");
        });
      }
    }
  });
});