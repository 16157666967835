define("discourse/plugins/discourse-multilingual/discourse/controllers/admin-multilingual-languages", ["exports", "discourse-common/utils/decorators", "@ember/controller", "discourse-common/lib/debounce", "@ember/runloop", "discourse/lib/computed", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-language", "@ember/object/computed", "@ember/array", "I18n"], function (_exports, _decorators, _controller, _debounce, _runloop, _computed, _multilingualLanguage, _computed2, _array, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("updatedLanguages.[]", "updateState"), _dec2 = (0, _decorators.default)("languages.[]", "customOnly"), (_obj = {
    refreshing: false,
    queryPlaceholder: (0, _computed.i18n)("multilingual.languages.query_placeholder"),
    updateState: "save",
    languages: [],
    updatedLanguages: (0, _array.A)(),
    anyLanguages: (0, _computed2.notEmpty)("filteredLanguages"),
    title() {
      return _I18n.default.t("multilingual.languages.title");
    },
    setupObservers() {
      this.addObserver("query", this._filterLanguages);
      this.addObserver("ascending", this._filterLanguages);
      this.addObserver("order", this._filterLanguages);
    },
    _filterLanguages() {
      // TODO: Use discouseDebounce when discourse 2.7 gets released.
      let debounceFunc = _debounce.default || _runloop.debounce;
      debounceFunc(this, this._refreshLanguages, 250);
    },
    updateLanguagesDisabled(updatedLanguages, updateState) {
      return updatedLanguages.length === 0 || updateState !== "save";
    },
    filteredLanguages(languages, customOnly) {
      if (customOnly) {
        return languages.filter(l => l.custom);
      }
      return languages;
    },
    _updateLanguages(languages) {
      this.setProperties({
        updatedLanguages: (0, _array.A)(),
        languages
      });
    },
    _refreshLanguages() {
      this.set("refreshing", true);
      let params = {};
      ["query", "ascending", "order"].forEach(p => {
        let val = this.get(p);
        if (val) {
          params[p] = val;
        }
      });
      _multilingualLanguage.default.list(params).then(result => {
        this._updateLanguages(result);
      }).finally(() => {
        this.set("refreshing", false);
      });
    },
    actions: {
      refreshLanguages() {
        this._refreshLanguages();
      },
      update() {
        if (this.updateLanguagesDisabled) {
          return;
        }
        this.set("updateState", "saving");
        _multilingualLanguage.default.save(this.updatedLanguages).then(result => {
          this._updateLanguages(result);
          this.set("updateState", "saved");
          setTimeout(() => {
            this.set("updateState", "save");
          }, 4000);
        });
      },
      updateLanguages(languages) {
        this._updateLanguages(languages);
      },
      languagesUploaded() {
        this.set("customOnly", true);
        this._refreshLanguages();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "updateLanguagesDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateLanguagesDisabled"), _obj), _applyDecoratedDescriptor(_obj, "filteredLanguages", [_dec2], Object.getOwnPropertyDescriptor(_obj, "filteredLanguages"), _obj)), _obj)));
});