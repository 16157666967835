define("discourse/plugins/discourse-multilingual/discourse/components/language-switcher-bar", ["exports", "discourse-common/utils/decorators", "@ember/object", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "@ember/object/computed", "@ember/component", "@ember/runloop", "I18n"], function (_exports, _decorators, _object, _multilingualRoute, _computed, _component, _runloop, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    classNames: "language-switcher-bar",
    showHidden: false,
    showHiddenToggle: (0, _computed.notEmpty)("hiddenLanguages"),
    setup() {
      const availableLanguages = this.availableLanguages();
      const currentLanguage = _I18n.default.currentLocale();
      let visibleList = this.siteSettings.multilingual_guest_language_switcher_footer_visible.split("|");
      availableLanguages.forEach(l => {
        if (l.locale === currentLanguage) {
          l.set("class", `${l.class} current`);
          if (visibleList.indexOf(l.locale) === -1) {
            visibleList.pop();
            visibleList.unshift(l.locale);
          }
        }
      });
      const visibleLimit = this.site.mobileView ? 3 : 10;
      let visibleLanguages = [];
      let hiddenLanguages = [];
      availableLanguages.forEach(l => {
        if (visibleList.indexOf(l.locale) > -1 && visibleLanguages.length < visibleLimit) {
          visibleLanguages.push(l);
        } else {
          hiddenLanguages.push(l);
        }
      });
      this.setProperties({
        visibleLanguages,
        hiddenLanguages
      });
    },
    availableLanguages() {
      return this.site.interface_languages.map(l => {
        return _object.default.create(Object.assign({}, l, {
          class: "language"
        }));
      });
    },
    didInsertElement() {
      this.set("clickOutsideHandler", (0, _runloop.bind)(this, this.clickOutside));
      $(document).on("click", this.clickOutsideHandler);
    },
    willDestroyElement() {
      $(document).off("click", this.clickOutsideHandler);
    },
    clickOutside(e) {
      const $hidden = $(".language-switcher-bar .hidden-languages");
      const $target = $(e.target);
      if (!$target.closest($hidden).length) {
        this.set("showHidden", false);
      }
    },
    actions: {
      change(locale) {
        this.set("showHidden", false);
        (0, _multilingualRoute.addParam)(_multilingualRoute.localeParam, locale, {
          add_cookie: true,
          ctx: this
        });
      },
      toggleHidden() {
        this.toggleProperty("showHidden");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj)), _obj)));
});