define("discourse/plugins/discourse-multilingual/select-kit/components/content-languages-dropdown", ["exports", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "@ember/object"], function (_exports, _dropdownSelectBox, _multilingualRoute, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    classNames: "content-languages-dropdown",
    valueProperty: "locale",
    nameProperty: "name",
    selectKitOptions: {
      icon: "translate",
      showFullTitle: false,
      autoFilterable: false,
      headerComponent: "content-languages-header",
      hasLanguages: false
    },
    didInsertElement() {
      if (!this.currentUser) {
        this.selectKit.options.set("filterable", true);
      }
    },
    modifyComponentForRow() {
      return "content-languages-row";
    },
    modifyContent(content) {
      if (!this.currentUser) {
        const param = (0, _multilingualRoute.getDiscoveryParam)(this, _multilingualRoute.contentLanguageParam);
        let activeIndex;
        content.forEach((l, i) => {
          if (l.locale === param) {
            (0, _object.set)(l, "classNames", `${l.classNames} active`);
            (0, _object.set)(l, "icon", "times");
            activeIndex = i;
          } else if (l.icon === "times") {
            (0, _object.set)(l, "classNames", "guest-content-language");
            (0, _object.set)(l, "icon", null);
          }
        });
        content.sort((a, b) => a.locale.localeCompare(b.locale));
        content.splice(0, 0, content.splice(activeIndex, 1)[0]);
      }
      return content;
    }
  });
});