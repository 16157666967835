define("discourse/plugins/discourse-multilingual/discourse/components/admin-language-list", ["exports", "@ember/object", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _object, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("allContentEnabled"), _dec2 = (0, _decorators.observes)("allInterfaceEnabled"), (_obj = {
    classNames: "admin-language-list",
    controlColumnClassNames: ["language-control"],
    allContentEnabled: false,
    allInterfaceEnabled: false,
    updateAllContent() {
      this.languages.forEach(l => {
        (0, _object.set)(l, "content_enabled", this.allContentEnabled);
      });
    },
    updateAllInterface() {
      this.languages.forEach(l => {
        if (l.locale !== "en") {
          (0, _object.set)(l, "interface_enabled", this.allInterfaceEnabled);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "updateAllContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateAllContent"), _obj), _applyDecoratedDescriptor(_obj, "updateAllInterface", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateAllInterface"), _obj)), _obj)));
});