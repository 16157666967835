define("discourse/plugins/discourse-multilingual/discourse/components/content-language-discovery", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "I18n"], function (_exports, _component, _object, _service, _multilingual, _multilingualRoute, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ContentLanguageDiscovery = _exports.default = (_class = class ContentLanguageDiscovery extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
    }
    get shouldRender() {
      return this.siteSettings.multilingual_enabled && this.siteSettings.multilingual_content_languages_enabled && this.siteSettings.multilingual_content_languages_topic_filtering_enabled && (this.currentUser || this.router.currentRouteName.indexOf("categories") === -1);
    }
    get contentLanguages() {
      let contentLangs = this.currentUser ? this.currentUser.content_languages : this.site.content_languages;
      if (contentLangs) {
        if (this.currentUser) {
          if (!contentLangs.some(l => l.locale === "set_content_language")) {
            contentLangs.push({
              icon: "plus",
              locale: "set_content_language",
              name: _I18n.default.t("user.content_languages.set")
            });
          }
        } else {
          contentLangs.forEach(l => {
            (0, _object.set)(l, "classNames", "guest-content-language");
          });
        }
      }
      return contentLangs;
    }
    get hasLanguages() {
      let hasLangs;
      if (this.currentUser && this.contentLanguages) {
        hasLangs = this.contentLanguages.filter(l => (0, _multilingual.isContentLanguage)(l.locale, this.siteSettings)).length > 0;
      } else {
        hasLangs = (0, _multilingualRoute.getDiscoveryParam)(this, _multilingualRoute.contentLanguageParam);
      }
      return hasLangs;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});